import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import moment from 'moment'
import global from "./function"
import './index.css'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'


const app = createApp(App).use(store).use(router)
.use(OneSignalVuePlugin, {
    appId: process.env.VUE_APP_ONESIGNALID,
    allowLocalhostAsSecureOrigin: true,
    serviceWorker: {
        path: "./OneSignalSDKWorker.js", // Hardcoded path
    },
    notifyButton: {
        enable: true,
        size: 'medium',
        position: 'bottom-left',
        theme: 'default',
        showCredit: false,
    },
    permissionPromptDisplay: true,
    requiresUserPrivacyConsent: true,
})
app.config.globalProperties.$onOneSignalReady = async function () {
const OneSignal = window.OneSignal || [];
OneSignal.push(() => {
    OneSignal.on('notification', (notification) => {
        console.log('Received notification:', notification);
        const title = notification.heading;
        const message = notification.content;
        console.log(`Title: ${title}, Message: ${message}`);
    });
});
};
app.config.globalProperties.axios = axios
app.config.globalProperties.moment = moment
app.config.globalProperties.global = global
app.mount('#app')